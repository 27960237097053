<template>
  <div>
    <Breadcrumbs main="" title="Incidents" />
    <div class="container-fluid">
      <div class="row p-3">
          <div class="col-md-1">
            Filter by User :
          </div>
          <div class="col-md-4">
            <b-form-select name="type" size="sm" v-model="selectedUser" :options="users_opt" @change="getIncedent()" required></b-form-select>
          </div>
      </div>
      <div class="row">
        <div class="col-md-12">
              <h4 class="text-info">Ongoing Incidents</h4>
                <b-row class="mt-3">
                <b-col class="col-md-6 col-12">
                  <b-input-group>
                    <b-input-group-prepend >
                      <span class="input-group-text search-bg"><i class="icofont icofont-search-alt-2"></i></span>
                    </b-input-group-prepend>
                    <b-form-input
                      class="col-md-5"
                      v-model="filter"
                      placeholder="Type to Search"
                      type="search"
                    ></b-form-input>
                  </b-input-group>
                </b-col>

                <b-col class="text-right col-md-6 col-12">
                  <b-form-group
                    label-cols="10"
                    label="Per page"
                    class="mb-0"
                  >
                    <b-form-select
                      class="col-md-10"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="getIncedent()"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="col-md-12" v-if="loading">
                <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              <div v-else class="table-responsive datatable-vue text-left">
                <b-table
                  show-empty
                  stacked="md"
                  :items="incedent_data"
                  :filter="filter"
                  :fields="tablefields"
                  @filtered="onFiltered"
                >
                <template #cell(status)="data">
                  <b v-if="data.item.status" class="text-success">Resolved</b>
                  <b v-else class="text-danger">Ongoing</b>
                </template>
                <template #cell(mName)="data">
                  <span class="font-weight-normal">{{data.item.mName}}</span>
                </template>
                <template #cell(duration)="data">
                  <span class="font-weight-normal">{{convertMsToHM(data.item.duration)}}</span>
                </template>
                <template #cell(createdAt)="data">
                  <span class="font-weight-normal">{{changeDateFormate(data.item.createdAt)}}</span>
                </template>
                <template #cell(details)="data">
                  <span class="link_txt" @click="OpenIncedentDetailModal(data.item)"> details </span>
                </template>

                <template #table-caption>Ongoing Incidents.</template>
                </b-table>
              </div>

              <b-col md="12" class="mt-3 text-right p-0">
                <div class="pagination">
                  <a v-show="currentPage != 1" @click="getIncedent('prev')">&laquo; Back</a>
                  <a class="active">{{currentPage}}</a>
                  <a v-show="incedent_data.length >= 1" @click="getIncedent('next')">Next &raquo;</a>
                </div>
              </b-col>
        </div>
      </div>
      <!-- Details Modal  -->
      <b-modal id="modal-incedent" size="lg"  class="theme-modal" v-model="show_details" no-close-on-backdrop hide-footer>
        <template #modal-title>
          <i v-if="incedent_details.status" class="fa fa-dot-circle-o text-success"></i>
          <i v-else class="fa fa-dot-circle-o text-danger"></i>
            Incident on <span class="font-weight-bold">{{incedent_details.mName}}</span>
        </template>

        <incedent-details :incedent_details="incedent_details"></incedent-details>

        <template #modal-footer>
        <b-button @click="show_details = false" color="danger">Close</b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>


<script>
import moment from 'moment'
import ManagerController from "../../../services/managerController";
import UserController from "../../../services/userController";
import HelperFunction from "../../../services/helperFunction"
import IncedentDetails from "../IncedentDetails.vue"
export default {
  name: "Incedent",
  components:{IncedentDetails},
  props:{monitor_id:String},
  data() {
    return {
      incedent_details:{},
      incedent_data: [],
      show_details:false,
      filter:null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 50],
      loading:false,
      tablefields: [
      { key: "mName", label: "Name", sortable: true },
      { key: "status", label: "Status", sortable: true },
      { key: "duration", label: "Duration", sortable: true },
      // { key: "comments", label: "Comments", sortable: true },
      { key: "createdAt", label: "Started At", sortable: true },
      { key: "details", label: "Details", sortable: true },
      ],
      users:{},
      selectedUser:null,
      users_opt:[{ value: null, text:'All Incidence' }]
    };
  },
  mounted() {
    this.getIncedent();
    this.getUsers();
  },
  watch:{
    monitor_id(){
      this.currentPage = 1
      this.getIncedent();
    }
  },
  methods: {
    OpenIncedentDetailModal(data){
      this.incedent_details = data
      this.show_details = true
    },
    async getIncedent(txt) {
      if(txt == 'next'){this.currentPage ++}
          if(txt == 'prev'){
            if(this.currentPage != 1){
            this.currentPage --
            }
          }
      var payload =''
      if(this.selectedUser != null){
        payload = '?currentPage='+this.currentPage+'&pageSize='+this.perPage+'&userid='+this.selectedUser
      }
      else{
        payload = '?currentPage='+this.currentPage+'&pageSize='+this.perPage
      }
      this.loading = true;
      var response = await ManagerController.getIncedent(payload);
      if (response.result) {
        this.incedent_data = []
        this.incedent_data = response.data;
        this.totalRows = this.incedent_data.length;
      }
      this.loading = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    changeDateFormate(d){
        return moment(d).format('D/M/YYYY, HH:mm A')
    },
    convertMsToHM(millis) {
      return HelperFunction.convertMsToHM(millis)
    },
    async getUsers(){
      let response = await UserController.getAllUsers()
      if (response.result) {
          this.users = response.message.filter(t=>{
            if(t.role != 'manager'){
                  return true;
              }
          });
          this.users.map((p)=> {
            this.users_opt.push({'value':p._id,'text':p.username+' ('+p.email+')'})
          })
      }
      else{
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
}

.pagination a.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.pagination a:hover:not(.active) {background-color: #ddd; cursor: pointer;}
</style>
