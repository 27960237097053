<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <px-card class="p-2">
          <b-row>
            <b-col class="box mr-3 p-3">
              <b-row class="custom-font-16"><b>Status</b></b-row>
              <b-row class="custom-font-22">
                <b :class="incedent_details.status ? 'text-success' : 'text-custom-danger'">
                  <div :class="['glowing-dot', incedent_details.status ? 'glowing-dot-up' : 'glowing-dot-down']"></div>
                  {{ incedent_details.status ? 'Resolved' : 'Ongoing' }}
                </b>
              </b-row>
            </b-col>
            <b-col class="box mr-3 p-3">
              <b-row class="custom-font-16"><b>Started at</b></b-row>
              <b-row class="custom-font-22">
                {{ changeDateFormate(incedent_details.createdAt) }}
              </b-row>
            </b-col>
            <b-col class="box p-3">
              <b-row class="custom-font-16"><b>Duration</b></b-row>
              <b-row class="custom-font-22">
                {{ convertMsToHM(incedent_details.duration) }}
              </b-row>
            </b-col>
          </b-row>
        </px-card>

        <!-- HTTP Details UI Start  -->
        <div v-if="incedent_details.mtype == 1" class="col-md-12">
          <b-row>
            <b-col class="box-2 p-3">
              <b-row class="custom-font-16"><b>{{ incedent_details.status ? 'Failure reason was' : 'Failure reason' }}</b></b-row>
              <b-row class="custom-font-24 mt-1 pl-2">{{ incedent_details.reason }}</b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="box-2 p-3 mt-4">
              <b-row class="custom-font-16"><b>Request</b></b-row>
              <b-row class="mt-2 custom-font-22" v-if="incedent_details.detail.reqObj">
                <div class="col">URL: {{ incedent_details.detail.reqObj.uri }}</div>

                <div class="col">
                  Method:
                  <span class="badge badge-success ml-2">{{ incedent_details.detail.reqObj.method }}</span>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <!-- HTTP Details UI End  -->

        <!-- Keyword Details UI Start  -->
        <div v-else-if="incedent_details.mtype == 2" class="col-md-12">
          <b-row>
            <b-col class="box-2 p-3">
              <b-row class="custom-font-16">
                <b>{{ incedent_details.status ? 'Failure reason was' : 'Failure reason' }}</b>
              </b-row>
              <b-row class="custom-font-24 mt-1 pl-2">{{ incedent_details.reason }}</b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="box-2 p-3 mt-4">
              <b-row class="custom-font-16 mb-3"><b>Request</b></b-row>

              <b-tabs content-class="mt-3 custom-font-16 cus-tab-font">
                <!-- URL Tab -->
                <b-tab title="URL" active>
                  <b-row class="mt-2 custom-font-22" v-if="incedent_details.detail.reqObj">
                    <div class="col">URL: {{ incedent_details.detail.reqObj.uri }}</div>
                    <div class="col">
                      Method:
                      <span class="badge badge-success ml-2">{{ incedent_details.detail.reqObj.method.toUpperCase() }}</span>
                    </div>
                  </b-row>
                </b-tab>

                <!-- Headers Tab -->
                <b-tab title="Headers" v-if="incedent_details.detail.resHeaders">
                  <b-row class="mt-2 ml-1 custom-font-22">Headers:</b-row>
                  <b-row class="col-md-12 mt-2">
                    <span class="col-md-12" v-for="[key, value] of Object.entries(incedent_details.detail.resHeaders)" :key="key">
                      <b-row class="col-md-12">
                        <code>{{ key }} : {{ value }}</code>
                      </b-row>
                    </span>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-button size="sm" variant="primary" class="custom-font-16" @click="download('htm.txt', incedent_details.detail.body)">
              Download File
            </b-button>
          </b-row>
        </div>

        <!-- Keyword Details UI End  -->

        <!-- Ping Details UI Start  -->
        <div v-else-if="incedent_details.mtype == 3" class="col-md-12">
          <b-row>
            <b-col class="box-2 p-3">
              <b-row class="custom-font-16"><b>{{ incedent_details.status ? 'Failure reason was' : 'Failure reason' }}</b></b-row>
              <b-row class="custom-font-24 mt-1 pl-2">{{ incedent_details.reason }}</b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="box-2 p-3 mt-4">
              <b-row class="custom-font-16"><b>Request</b></b-row>
              <b-row class="mt-2 custom-font-22" v-if="incedent_details.detail.inputHost">
                <div class="col">URL: {{ incedent_details.detail.inputHost }}</div>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <!-- Ping Details UI End  -->

        <!-- Port Details UI Start  -->
        <div v-else-if="incedent_details.mtype == 4" class="col-md-12">
          <b-row>
            <b-col class="box-2 p-3">
              <b-row class="custom-font-16"><b>{{ incedent_details.status ? 'Failure reason was' : 'Failure reason' }}</b></b-row>
              <b-row class="custom-font-24 mt-1 pl-2">{{ incedent_details.reason }}</b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="box-2 p-3 mt-4">
              <b-row class="custom-font-16"><b>Request</b></b-row>
              <b-row class="mt-2 custom-font-22" v-if="incedent_details.detail.port">
                <div class="col">URL: {{ incedent_details.detail.host+':'+incedent_details.detail.port }}</div>
              </b-row>
            </b-col>
          </b-row>

        </div>
        <!-- Port Details UI End  -->

        <!-- HeartBeat Details UI Start  -->
        <div v-else-if="incedent_details.mtype == 5" class="col-md-12">
          <b-row>
            <b-col class="box-2 p-3">
              <b-row class="custom-font-16"><b>{{ incedent_details.status ? 'Failure reason was' : 'Failure reason' }}</b></b-row>
              <b-row class="custom-font-24 mt-1 pl-2">{{ incedent_details.reason }}</b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="box-2 p-3 mt-4">
              <b-row class="custom-font-16"><b>Request</b></b-row>
              <b-row class="mt-2 custom-font-22" v-if="request_uri">
                <div class="col">URL: {{ request_uri }}</div>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="mt-4 box-2">
              <b-row class="custom-font-16 mt-3 pl-2">
                <b>Details</b>
              </b-row>
              <div class="table-responsive datatable-vue text-center custom-font-16">
                <b-table show-empty stacked="md" :items="hertbeat_log_data" :fields="tablefields">
                  <template #cell(user_agent)="data">
                    {{ data.item.headers['user-agent'] }}
                  </template>
                  <template #cell(createdAt)="data">
                    <span class="text-info">{{ changeDateFormate(data.item.createdAt) }}</span>
                  </template>
                </b-table>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- HeartBeat Details UI End  -->
        <div v-else class="col-md-12 p-3 box-2">
            <b-row class="custom-font-16"><b>{{ incedent_details.status ? 'Failure reason was' : 'Failure reason' }}</b></b-row>
            <b-row class="custom-font-24 mt-1 pl-2">{{ incedent_details.reason }}</b-row>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import moment from "moment";
import Config from "../../services/config";
import DashboardController from "../../services/dashboardController";
import HelperFunction from "../../services/helperFunction"
export default {
  name: "IncedentDetails",
  props: { incedent_details: Object },
  data() {
    return {
      monitor_type_opt: [
        { value: null, text: "Please Select" },
        { value: "1", text: "HTTP(s)" },
        { value: "2", text: "Keyword" },
        { value: "3", text: "Ping" },
        { value: "4", text: "Port" },
        { value: "5", text: "Heartbeat" },
        { value: '6', text:'Page Speed' },
        { value: '7', text:'Page Screenshot' }
      ],
      request_uri:'',
      hertbeat_log_data : [],
      tablefields:[
      { key: "ip", label: "IP", sortable: true },
      { key: "user_agent", label: "UserAgent", sortable: true },
      { key: "createdAt", label: "Last Ping On", sortable: true },
      ]
    };
  },
  mounted(){
    if(this.incedent_details.mtype == 5){
        this.request_uri = Config.hertbeatUrl + this.incedent_details.mId
        this.getDetails(this.incedent_details.mId)
    }
  },
  methods: {
    async getDetails(id){
        this.hertbeat_log_data = []
        var response = await DashboardController.keywordLog(id);
        if(response.result){
            this.hertbeat_log_data = response.data
        }
    },
    changeDateFormate(d) {
      return moment(d).format("D/M/YYYY, hh:mm A");
    },
    convertMsToHM(millis) {
      return HelperFunction.convertMsToHM(millis)
    },
    download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    }
  },
};
</script>



<style scoped>
.box {
  background: #f6faff;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  border-radius: 10px;
}
.box-2 {
  background: #ffffff;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  border-radius: 10px;
}


.glowing-dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 2px;
}

.glowing-dot-up {
  background-color: #28a745;
  animation: glow-green 2s ease-in-out infinite;
}

.glowing-dot-down {
  background-color: #DF484A;
  animation: glow-red 2s ease-in-out infinite;
}

@keyframes glow-green {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 3px #28a745;
  }
  50% {
    transform: scale(1);
    box-shadow: 0 0 6px #28a745;
  }
  100% {
    transform: scale(0.98);
    box-shadow: 0 0 3px #28a745;
  }
}

@keyframes glow-red {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 3px #DF484A;
  }
  50% {
    transform: scale(1);
    box-shadow: 0 0 6px #DF484A;
  }
  100% {
    transform: scale(0.98);
    box-shadow: 0 0 3px #DF484A;
  }
}


</style>